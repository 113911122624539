import React from 'react';
import Typography from '../../components/Typography/Typography';
import * as classes from './WhyWeSection.module.scss';

export default function WhyJoinAsExpert() {
  return (
    <div className={classes.whyWeSection}>
      <Typography variant="h1" align="center">
        Why Join Experts Circle?
      </Typography>

        <Typography variant="p">
        <br/>
          <p><b>Become a Trusted Expert</b>: Leverage your industry knowledge and professional connections to create new opportunities for yourself and others. 
            This role is designed to complement your existing career, offering a way to grow professionally without disrupting your primary job.</p>
          <ul>
            <li><b>Built for Experts: </b> We empower industry professionals to connect businesses with the right talent using their insider knowledge.</li>
            <li><b>Verified and Trusted: </b>As a verified expert, your recommendations carry weight and credibility, giving businesses confidence in the candidates you suggest.</li>
            <li><b>Simple and Transparent: </b> Everything you need is in one place. Track recommendations, communicate with businesses, and manage your earnings seamlessly.</li>
          </ul>          
        </Typography>   
      <br/>
      <Typography variant="p">
        <h4>Join a Community of Experts</h4>          
        At Experts Circle, we blend cutting-edge technology with the insights of industry professionals to create a talent-matching ecosystem that is efficient, reliable, and innovative. Whether you're an employer seeking top-tier talent, a job seeker looking for your next opportunity, or an expert ready to leverage your knowledge to connect people with success, Experts Circle is your platform where expertise drives progress.
      </Typography>        
    </div>
  );
}
